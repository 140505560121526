import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  checkAll() {
    let checked = document.getElementsByName('check-all')[0].checked;
    document.getElementsByName('degree-id').forEach(elem => elem.checked = checked)
  }

  signAll() {
    document.getElementsByName('degree-id').forEach(elem => {
      if (elem.checked) {
        Rails.ajax({
          type: "put",
          url: `/degrees/${elem.value}/workflow_next_steps/sign`
        })
      }
    })
  }
}
