import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];

  view() {
    const object = this.#createObject(this.linkTarget.dataset.pdf);
    const container = document.getElementById("pdf-content-container");
    container.innerHTML = "";
    container.appendChild(object);
  }

  #createObject(url) {
    const object = document.createElement("object");
    object.type = "application/pdf";
    object.width = "100%";
    object.height = "100%";
    object.data = url;

    return object;
  }
}
