import { Controller } from "@hotwired/stimulus";
import EduSigner from "edu-signer-js"
import { format, parseJSON } from 'date-fns'

const signer = new EduSigner({
  licenseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjZXJ0aWZpY2FlZHUuY29tLmJyIiwiZXhwIjozMjUwMzYwMDgwMH0.vqfFSV4mqs5aSggT59iiIF2tSkQvrIFq5FKMy6WORJk'
})

const pki = signer.PKI

export default class extends Controller {
  connect() {
    this.load()
  }

  load() {
    const newSelect = document.getElementById('certificate_content_hash');
    pki.getCertificates().then(certs => {
      certs.forEach(function(cert) {
        var opt = document.createElement("option")
        let validity = parseJSON(cert.validity)
        opt.value = cert.thumbprint
        opt.innerHTML = `${format(validity, 'dd/MM/yyyy HH:mm:ss')} - ${cert.subject}`

        newSelect.appendChild(opt);
      })
      this.handleChange()
    })
  }

  handleChange() {
    const newSelect = document.getElementById('certificate_content_hash');
    const newInput = document.getElementById('certificate_content_base64')

    pki.getCertificate(newSelect.value).then(cert => {
      newInput.value = cert.base64
    })
  }

  test(event) {
    event.preventDefault()
    const newSelect = document.getElementById('certificate_content_hash')

    if (newSelect.value == '') {
      alert('Selecione um certificado!\nSe ele não aparecer na lista verifique o token!')
    } else {
      pki.signData(newSelect.value, btoa('Hello World'))
        .then(data => {
          if ('error' in data)
            alert('Erro! Verifique se o token foi inserido!')
          else
            alert('Seu certificado esta funcionando!')
        })
        .catch(err => { alert('Erro! Verifique se o plugin esta em execução!') })
    }
  }
}
