import consumer from "./consumer"
import EduSigner from "edu-signer-js"

const signer = new EduSigner({
  licenseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjZXJ0aWZpY2FlZHUuY29tLmJyIiwiZXhwIjozMjUwMzYwMDgwMH0.vqfFSV4mqs5aSggT59iiIF2tSkQvrIFq5FKMy6WORJk'
})
const pki = signer.PKI

consumer.subscriptions.create("SignerChannel", {
  connected() {
    pki.getVersion().then(data => {
      console.log('Version: ' + data.version);
    });
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data['message'] !== 'Ping'){
      pki.signData(data['thumbprint'], data['message']).then(dataSigned => {
        this.perform('sign', { signature_id: data['signature_id'], message: dataSigned['DataSigned'] })
      })
    }
  }
})
