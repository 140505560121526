// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import {
  Alert,
  Autosave,
  Dropdown,
  Modal,
  Tabs,
  Popover,
  Toggle,
  Slideover,
} from "tailwindcss-stimulus-components";

window.Stimulus = Application.start();
Stimulus.warnings = true;
Stimulus.debug = false;

const context = require.context("../controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

Stimulus.register("alert", Alert);
Stimulus.register("autosave", Autosave);
Stimulus.register("dropdown", Dropdown);
Stimulus.register("modal", Modal);
Stimulus.register("tabs", Tabs);
Stimulus.register("popover", Popover);
Stimulus.register("toggle", Toggle);
Stimulus.register("slideover", Slideover);

import "../stylesheets/application.scss";

require.context("../images", true);

ActiveStorage.start();

import "@nathanvda/cocoon";

document.addEventListener("turbo:before-fetch-response", function(event) {
  if (typeof (event.detail.fetchResponse) !== 'undefined') {
    var response = event.detail.fetchResponse.response
    if (response.redirected) {
      event.preventDefault()
      Turbo.visit(response.url)
    }
  }
})
